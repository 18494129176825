/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

@media (max-width: 992px) {
  .bodyy {
    display: none; /* Hide the content when the screen width is below 991px */
  }
  .bodyyyy {
    display: block;
  }
}

@media (min-width: 992px)  {
  .bodyy {
    display: block; /* Hide the content when the screen width is below 991px */
  }
  .bodyyyy {
    display: none;
  }
}

@media (min-width: 1024px)  and (max-width:1200px) {
  .button_item {
    text-align: center;
    width: 100%;
    padding-top: 17px !important;
  }
  .table_body {
    font-size: 15px !important;
    font-weight: 500;
  }
  .table_head1{
    font-size: 15px !important;
    font-weight: 500;
  }
  .adimn_dashnbord {
    font-size: 16px !important;
   
  }
  .admin_header {
    font-size: 27px !important;
  
  }
  .holiday_head h1 {
    font-size: 30px;
  }
  .holiday_head{
    font-size: 30px;
  }
  .heads{
    font-size: 27px !important;
  }
  .head1{
    font-size: 17px !important;
  }
  .h5_class{
    font-size: 18px !important;
  }
 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'DM Sans', sans-serif;
}

::selection {
  background-color: #6a2a69;
  color: #ffffff;
}

/* Login Page  */

.login_page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.login_card {
  border-radius: 10px;
  padding: 70px 70px 70px 10px;
}

.login_head {
  font-weight: 800;
  font-size: 30px;
  font-family: emoji;
  color: #6a2a69;
}
.login_form input {
  padding: 25px;
  border-radius: 10px;
}

.form-control {
  border-color: #6a2a69;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #6a2a69 !important;
  outline: 0;
  box-shadow: none !important;
}
.login_form button {
  background-color: transparent;
  padding: 7px 25px;
  color: #6a2a69;
  font-weight: 400;
  /* border: 1px solid #6a2a69; */
  border-radius: 7px;
  border: none;
}
.parent {
  background-color: #f7efff;
}
.login_button {
  width: 100%;
  border: none;
  padding: 8px;
  background-color: #6a2a69;
  color: #ffffff;
  border-radius: 7px;
}
.forgot {
  font-size: 16px;
  color: #6a2a69;
}
.side {
  background-color: #ffffff;
}

@media screen and (min-width: 0px) and (max-width: 320px) {
  .login_card {
    padding: 10px !important;
  }

  .logo {
    width: 110px;
    height: 100px;
    margin: auto;
    margin-bottom: 15px;
  }
  .login_head {
    font-size: 25px;
  }
}

@media screen and (min-width: 321px) and (max-width: 768px) {
  .login_card {
    padding: 10px !important;
  }

  .logo {
    width: 140px;
    height: 100px;
    margin: auto;
    margin-bottom: 30px;
  }
  .login_head {
    font-size: 25px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* DASHBOARD CSS */
.sidebar_item {
  background-color: #733272;
  color: white;
  height: 100vh;
}
.sidebar {
  /* height: 200px !important; */
}
.sidebar_bg {
  background-color: #733272;
}
.active {
  background-color: #f7abf574;
  border-radius: 10px;
}

.employe_detail {
  /* padding-left: 25px; */
}

.user_icon {
  font-size: 45px;
  border: 5px solid #ffffffa6;
  border-radius: 100%;
  padding: 8px;
  background-color: rgba(192, 192, 192, 0.515);
  margin-left: 10px;
}
.employement {
  padding: 10px 10px 10px 3px;
  font-weight: 500;
  font-size: 16px;
  width: fit-content;
  cursor: pointer;
}
.button_item {
  text-align: center;
  width: 100%;
  padding-top: 60px;
}

.logout_button {
  padding: 8px;
  width: 100%;
  border: none;
  border-radius: 7px;
  font-weight: 500;
  font-size: 18px;
}

.employe_icon {
  font-size: 15px;
  border: 1px solid white;
  border-radius: 100%;
  padding: 10px;
}
.image_section {
  padding-left: 25px;
}
.logo_img {
  height: 120px;
}
.logo_img1 {
  height: 110px;
}
.admin_header {
  font-size: 35px;
  font-weight: 500;
  font-family: revert;
  color: #6a2a69;
  margin-bottom: 0px !important;
}

.admin_name {
  font-size: 16px !important;
  font-weight: 500 !important;
}
.admin_id {
  font-size: 15px;
  font-weight: 400;
}
.admin_section {
  border-bottom: 1px solid rgba(120, 119, 120, 0.577);
}

/* FORM CSS */

.form_item {
  border-radius: 20px;
  padding-left: 5px;
  padding-top: 20px;
  background-color: #fff;
  /* width: 100%; */
}
.adimn_dashnbord {
  font-size: 19px;
  font-weight: bold;
  font-family: system-ui;
  margin-bottom: 25px !important;
}
.employement_item {
  padding: 8px;
  width: 200px;
  border: none;
  margin: 10px;
  border-radius: 7px;
  font-weight: 500;
  font-size: 18px;
  background-color: #733272;
  color: #fff;
}
.employement_button {
  text-align: end;
}
.form-control {
  padding: 25px;
  border: 1px solid #740f72;
  border-radius: 8px;
}
.form-control:focus {
  box-shadow: none;
  border: 1px solid #740f72;
}
label {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 5px !important;
  font-family: system-ui;
}
/* TABLE CSS */

.table_item {
  padding-left: 20px;
  padding-top: 25px;
}

.table_head {
  background-color: #740f72;
  color: #fff;
}
.table_body {
  font-size: 17px;
  font-weight: 500;
}
.table_button {
  border: none;
  padding: 3px;
  border-radius: 5px;
  width: 100%;
}
/* FEEDBACK */
.view_button {
  text-align: end;
}
.card {
  padding: 6px;
  border: 1px solid grey;
  border-radius: 12px;
}
.week_item {
  font-size: 17px;
  font-weight: 500;
}
.date_item {
  color: rgb(95, 93, 93);
  font-size: 17px;
}
.view_button {
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}
.view_button:hover {
  color: #6a2a69;
  text-decoration: underline;
}
.back_button {
  background-color: #733272;
  color: #fff;
  font-size: 19px;
  border: none;
  border-radius: 8px;
  padding: 6px;
  width: 150px;
}
.admin_header1 {
  font-size: 25px;
  font-weight: 500;
}
.adimn_dashnbord1 {
  font-size: 18px;
  font-weight: 400;
}
.status {
  font-size: 17px;
  font-weight: 500;
}
.finish {
  font-size: 16px;
  color: green;
  font-weight: 500;
}
.pending {
  font-size: 16px;
  color: red;
  font-weight: 500;
}
.month {
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center !important;
}

.symbol {
  font-size: 30px;
}
.feddback_item {
  padding-left: 20px;
}
.selected_option {
  font-size: 17px;
  font-weight: 500;
  padding-left: 15px;
}
/* TEXTAREA */
.text_area {
  border-radius: 6px !important;
  height: 200px;
  width: 100%;
  border: 1px solid #c7c6c6;
  /* overflow-y: scroll;   */
}
.table_button1 {
  background-color: #733272;
  color: #fff;
  border: none;
  font-size: 18px;
  padding: 6px;
  border-radius: 5px;
  width: 100px;
}
.modal {
  background-color: black;
}
/* .modal-dialog{
  position: relative;
} */

.modal-content {
  border-radius: 10px;
}
::-webkit-scrollbar {
  width: 1px;
  height: 0px;
  border-radius: 5px;
}
::-webkit-scrollbar-track {
  background: #bfbdbf;
}
::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
::-webkit-scrollbar-thumb:active {
  background: #333;
}
.give_button {
  background-color: #733272;
  color: #fff;
  border: none;
  font-size: 16px;
  padding: 6px;
  border-radius: 5px;
  width: fit-content;
}

/* Work Sheet */

.date_select {
  font-size: 17px;
  font-weight: 500;
}
.day_item {
  font-size: 20px;
  font-weight: 500;
}
.week_item {
  font-size: 18px;
  font-weight: 500;
}
.week_item1 {
  font-size: 16px;
  font-weight: 500;
  text-align: start;
}
.october {
  font-size: 33px;
  font-weight: 500;
}
.current {
  font-size: 17px;
  color: rgb(77, 77, 77);
}
.submit-button {
  background-color: #733272;
  color: #fff;
  border: none;
  font-size: 16px;
  padding: 6px;
  border-radius: 5px;
  width: 100px;
}
.save {
  text-align: end;
}
textarea {
  border-radius: 10px;
  border: 1px solid #6a2a69;
}
.more_button {
  text-align: end;
}

.sidebarr {
  height: 100vh;
}

.custom-toast-custom {
  background-color: #733272 !important;
  color: white !important;
}

.custom-toast-error-custom
{
  background-color: rgba(180, 44, 44, 0.804)important;
  color: white !important;
}
.employee_filter_searchBar::placeholder {
  font-size: 15px;
}

.employee_filter_select {
  font-size: 13px;
}

/* trainer list dropdown */
.select_item {
  padding: 13px;
  border: 1px solid #6a2a69 !important;
  border-color: #6a2a69;
  border-radius: 8px;
}
.password_input {
  border: 1px solid #6a2a69;
  border-radius: 8px !important;
}
svg {
  overflow: hidden;
  vertical-align: middle;
  font-size: 20px;
}
.input_password {
  border: none !important;
}
.login_btn {
  background-color: #6a2a69 !important;
  color: #fff !important;
  font-weight: 700 !important;
  border-radius: 7px;
  padding: 7px 25px !important;
}
a {
  color: #fff;
}
a:hover {
  color: #fff;
  text-decoration: none;
}

td a {
  color: #000000;
}
td a:hover {
  color: #6a2a69;
  text-decoration: underline;
  cursor: pointer;
}

.forgot_password {
  color: #6a2a69;
  cursor: pointer;
}
.forgot_password:hover {
  color: red;
  text-decoration: underline;
}
.date_item {
  border-radius: 5px;
  border: 1px solid #6a2a69;
}
.employee_filter_searchBar {
  border: 1px solid #6a2a69;
  border-radius: 7px;
}
.employee_filter_select {
  border: 1px solid #6a2a69;
  border-radius: 7px;
}

.disableInput:disabled {
  background-color: #e9ecef;
  cursor: not-allowed !important;
}

.employee_filter_select:disabled {
  background-color: #c7c6c67b;
  cursor: not-allowed !important;
}

.textAreaFields:disabled {
  background-color: #c7c6c67b;
  cursor: not-allowed !important;
}

select:disabled {
  background-color: #e9ecef;
  cursor: not-allowed;
}

.suggestions_content {
  font-weight: 400;
}
.good_img {
  height: 45px;
}
.input_password {
  border: none !important;
}
.password_input {
  border: 1px solid #6a2a69;
  border-radius: 8px !important;
}
.flex-item {
  display: flex;
  border: 1px solid #6a2a69;
  border-radius: 7px;
}

.admin_header {
  font-size: 35px;
  font-weight: 500;
  font-family: revert;
  color: #6a2a69;
  margin-bottom: 0px !important;
}

.admin_name {
  font-size: 16px;
  font-weight: 500;
}
.admin_id {
  font-size: 15px;
  font-weight: 400;
}
.admin_section {
  border-bottom: 1px solid rgba(120, 119, 120, 0.577);
}

.Admin_date {
  background-color: transparent;
  border: 1px solid #6a2a69;
  border-radius: 10px;
  width: 300px;
  color: #000;
  margin-right: 20px;
  outline: none;
}
.Admin_date_i {
  height: 60px;
}

.Admin_date1 {
  background-color: #6a2a69;
  border: none;
  color: #fff;
  font-weight: 500;
  border-radius: 10px;
  width: 200px;
  margin-right: 20px;
}

.Admin_date2 {
  border: 1px solid #6a2a69;
  color: #fff;
  font-weight: 500;
  border-radius: 10px;
  width: 220px;
  margin-right: 20px;
  background-color: #6a2a69;
}

.update_holiday {
  background-color: #6a2a69;
  border: none;
  border-radius: 10px;
  width: fit-content;
  font-weight: 500;
  margin-right: 20px;
  color: #fff;
}

.show_holiday {
  background-color: #6a2a69;
  border: none;
  color: #fff;
  border-radius: 10px;
  font-weight: 500;
}
.Admin_holiday_banner {
  /* background-image: url("../public/images/admin_holiday.jpg"); */
  height: 100vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: auto;
}
.holiday_day {
  color: red;
  font-size: 30px;
}
.table_heading {
  background-color: #6a2a69;
}
.holiday_table {
  height: 100px !important;
  overflow-y: scroll;
  width: 75%;
}
.holiday_banner {
  background-image: url("../public/images/Festival\ Banner.png");
  height: auto;
  width: 100%;
  /* background-position: center; */
  background-repeat: no-repeat;
  background-size: cover;
  color: #6a2a69;
  border-radius: 20px;
}
.submit-button1 {
  background-color: #fff;
  color: #733272;
  border: 1px solid #733272;
  font-size: 17px;
  font-weight: 500;
  padding: 10px;
  border-radius: 7px;
  width: 130px;
  margin-top: 30px;
}
.submit-button1:hover{
  background-color: #733272;
  color: #fff;
  transform: scale(1.1, 1.1);
  transition: 0.5s;
}
.note_para {
  font-size: 14px;
  color: rgb(255, 68, 0);
}

.holiday_head {
  text-align: center;
  font-family: 'Averia Serif Libre', serif !important;

}
.holiday_head h1 {
  color: #6a2a69;
  margin-top: 25px;
}

.holiday_sec2 {
  background-image: url("https://img.freepik.com/free-photo/white-paper-texture_1194-5416.jpg?w=900&t=st=1697534439~exp=1697535039~hmac=e232a97b54f60d91ca564753fd9b4d0c2c0769f86c30cb3fa80ec365591464f9");
}


.holidaysss {
  color: #fff;
  padding: 10px;
  display: inline-block;
  border-radius: 5px;
  animation: blinkingBackground 1s infinite;
  font-size: 30px !important;
  font-weight: 700;
  font-family: 'Averia Serif Libre', serif !important;

}
@keyframes blinkingBackground {
  0% {
    color: #10c018;
  }
  25% {
    color: #1056c0;
  }
  50% {
    color: #ef0a1a;
  }
  75% {
    color: rgb(82, 0, 102);
  }
  100% {
    color: #d504a1;
  }
}

.star {
  font-size: 17px;
  padding-top: -10px;
  color: red;
}
sup {
  top: 0em;
}
.holidayWishes {
  font-size: 23px;
  text-transform: capitalize;
  font-family: 'Averia Serif Libre', serif !important;
}
.banner_parrent {
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.table_head1{
  font-weight: 500;
}
.save_btn{
  background-color: #733272 !important;
}


.employee_type_filter{
  padding: 10px;
}

.back-arrow{
  background: none;
  border: none;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 700;
  color: #6a2a69;
}

/* LoaderSpinner.css */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
